import { Box } from '@mui/material';
import type { ReactNode } from 'react';

import { Scanner } from './Scanner';
import { Sidebar } from './Sidebar';
import { TopBar } from './TopBar';

export const Dashboard: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Box className="w-64 mobile:hidden tablet:block desktop:block">
        <Sidebar />
      </Box>
      <Box className="p-5 bg-kc-grey-8 flex-1 flex flex-col gap-4">
        <TopBar />
        <Box className="flex-1 max-h-[calc(100vh-104px)] overflow-auto">
          {children}
        </Box>
      </Box>
      <Scanner />
    </>
  );
};
