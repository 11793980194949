import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ky from 'ky';

export const promoCodeApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_SERVICE_URL}/uaps/api/v1`,
    fetchFn: async (...args) => ky(...args),
  }),

  endpoints: (builder) => ({
    getPromoCodeList: builder.query({
      query: () => `/promo-codes/all`,
    }),

    updatePromoCode: builder.mutation({
      query: ({ promoCodeId, payload }) => ({
        url: `/promo-codes/update/${promoCodeId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    addPromoCode: builder.mutation({
      query: (payload) => ({
        url: `/promo-codes/add`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),

  reducerPath: 'promoCodeApi',
});

export const {
  useGetPromoCodeListQuery,
  useAddPromoCodeMutation,
  useUpdatePromoCodeMutation,
} = promoCodeApi;
