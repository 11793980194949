import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import type { SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import Packages from './features/admin/packages';
import PromoCode from './features/admin/promoCode';
import { ListUsers } from './features/admin/users/ListUsers';
import { ForgotPasword } from './features/auth/ForgotPasword';
import { Login } from './features/auth/Login';
import { CheckinList } from './features/checkin/CheckinList';
import { Reservations } from './features/Reservations';
import { Dashboard } from './layout/Dashboard';
import { FullScreenLoading } from './layout/FullScreenLoading';
export type RoutesType = {
  name: string;
  path: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  element?: JSX.Element;
  isAuthenticated: boolean;
  isAdminOnly: boolean;
  children?: Array<RoutesType>;
};

export const getRoutes = (
  isAuthenticated?: boolean,
  isAdmin?: boolean,
): Array<RoutesType> => {
  return [
    {
      name: 'Dashboard',
      path: '/',
      Icon: DashboardOutlinedIcon,
      element: isAuthenticated ? (
        <Dashboard>
          <CheckinList listType="active" />
        </Dashboard>
      ) : (
        <Navigate to={'/login'} />
      ),
      isAuthenticated: true,
      isAdminOnly: false,
    },
    {
      name: 'Waiting Area',
      path: '/waiting',
      Icon: AccessTimeOutlinedIcon,
      element: (
        <Dashboard>
          <CheckinList listType="waiting" />
        </Dashboard>
      ),
      isAuthenticated: true,
      isAdminOnly: false,
    },

    {
      name: 'Reservations',
      path: '/reservations',
      Icon: CalendarMonthIcon,
      element: (
        <Dashboard>
          <Reservations />
        </Dashboard>
      ),
      isAuthenticated: true,
      isAdminOnly: false,
    },
    {
      name: 'Admin Console',
      path: '/admin',
      Icon: AdminPanelSettingsOutlinedIcon,
      isAuthenticated: true,
      isAdminOnly: true,
      children: [
        {
          name: 'Users',
          path: '/admin/users',
          element: isAdmin ? (
            <Dashboard>
              <ListUsers />
            </Dashboard>
          ) : (
            <Navigate to={'/'} />
          ),
          isAuthenticated: true,
          isAdminOnly: true,
        },
        {
          name: 'Promo code',
          path: '/admin/promo',

          element: (
            <Dashboard>
              <PromoCode />
            </Dashboard>
          ),
          isAuthenticated: true,
          isAdminOnly: false,
        },
        {
          name: 'Packages',
          path: '/admin/packages',

          element: (
            <Dashboard>
              <Packages />
            </Dashboard>
          ),
          isAuthenticated: true,
          isAdminOnly: false,
        },
      ],
    },
    {
      name: 'Login',
      path: '/login',
      element: isAuthenticated ? <Navigate to={'/'} /> : <Login />,
      isAuthenticated: false,
      isAdminOnly: false,
    },
    {
      name: 'Forgot Password',
      path: '/forgot-password',
      element: isAuthenticated ? <Navigate to={'/'} /> : <ForgotPasword />,
      isAuthenticated: false,
      isAdminOnly: false,
    },
  ];
};

export const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await fetchAuthSession();
        setLoading(false);
        if (!res.userSub) throw new Error('Unauthenticated');
        const idToken = res.tokens?.idToken?.payload;
        setIsAuthenticated(true);
        if (
          Array.isArray(idToken?.['cognito:groups']) &&
          idToken['cognito:groups'].includes('admin')
        ) {
          setIsAdmin(true);
        }
      } catch (e) {
        setIsAuthenticated(false);
      }
    })();
  }, [location.pathname]);

  const routes = useRoutes(getRoutes(isAuthenticated, isAdmin));

  if (loading) return <FullScreenLoading />;
  return routes;
};
