import type {
  UserType,
  ListUsersInGroupCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import { ListUsersInGroupCommand } from '@aws-sdk/client-cognito-identity-provider';
import { useCallback, useEffect, useState } from 'react';

import { getCognitoIdentityProviderClient } from '../../../utils/getCognitoIdentityProviderClient';
import { awsConfig } from '../../auth/config';

export type UsersType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  group: string;
  status: boolean;
}[];

export const useListUsers = (): {
  users: UsersType | undefined;
  refetch: () => void;
  isLoading: boolean;
  error: unknown;
} => {
  const [users, setUsers] = useState<UsersType | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const identityProviderClient = await getCognitoIdentityProviderClient();

      // List Admins
      const listAdminUsersInGroupInput: ListUsersInGroupCommandInput = {
        UserPoolId: awsConfig.userPoolId,
        GroupName: 'admin',
      };
      const adminCommand = new ListUsersInGroupCommand(
        listAdminUsersInGroupInput,
      );
      const adminUsersResult = await identityProviderClient.send(adminCommand);

      // List Staffs
      const listStaffUsersInGroupInput: ListUsersInGroupCommandInput = {
        UserPoolId: awsConfig.userPoolId,
        GroupName: 'staff',
      };
      const staffCommand = new ListUsersInGroupCommand(
        listStaffUsersInGroupInput,
      );
      const staffUsersResult = await identityProviderClient.send(staffCommand);

      const getAttributes = (user: UserType, group: string) => {
        return {
          firstName:
            user.Attributes?.find((att) => att.Name === 'name')?.Value || '',
          lastName:
            user.Attributes?.find((att) => att.Name === 'family_name')?.Value ||
            '',
          email:
            user.Attributes?.find((att) => att.Name === 'email')?.Value || '',
          phone:
            user.Attributes?.find((att) => att.Name === 'phone_number')
              ?.Value || '',
          group: group || '',
          status: user.Enabled || false,
        };
      };
      setUsers([
        ...(adminUsersResult.Users || []).map((user) =>
          getAttributes(user, 'admin'),
        ),
        ...(staffUsersResult.Users || []).map((user) =>
          getAttributes(user, 'staff'),
        ),
      ]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  return {
    users,
    refetch: getUsers,
    isLoading,
    error,
  };
};
