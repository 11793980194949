const DayPicker = {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: '#F39200 !important',
      },
    },
  },
};

export default DayPicker;
