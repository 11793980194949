import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@mui/material';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../assets/logo.svg';
import type { RoutesType } from '../routes';
import { getRoutes } from '../routes';

const RenderRoutes: React.FC<{ navs: Array<RoutesType>; isAdmin: boolean }> = ({
  navs,
  isAdmin,
}) => {
  return navs
    .filter((nav) => !nav.isAdminOnly || (nav.isAdminOnly && isAdmin))
    .map((nav) =>
      nav.children ? (
        <Accordion
          key={nav.path}
          disableGutters={true}
          className="shadow-none [background-color:transparent]"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="text-2xl text-white" />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={clsx(
              'flex gap-2 no-underline px-[10px] py-[6px] rounded text-base font-semibold transition-colors duration-400 min-h-0 bg-none text-white shadow-none hover:bg-kc-orange-2',
            )}
            sx={{
              '.MuiAccordionSummary-content': {
                margin: '0px',
                display: 'flex',
                gap: '8px',
              },
            }}
          >
            {nav.Icon && <nav.Icon />}
            {nav.name}
          </AccordionSummary>
          <AccordionDetails>
            <RenderRoutes
              navs={nav.children.filter((nav) => nav.isAuthenticated)}
              isAdmin={isAdmin}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Link
          key={nav.path}
          to={nav.path}
          className={clsx(
            'flex gap-2 no-underline px-[10px] py-[6px] rounded text-base font-semibold transition-colors duration-400',
            {
              'bg-white text-kc-grey shadow-[0px 1px 4px 0px #00000029]':
                location.pathname === nav.path,
              'bg-none text-white shadow-none hover:bg-kc-orange-2':
                location.pathname !== nav.path,
            },
          )}
        >
          {nav.Icon && <nav.Icon />}
          {nav.name}
        </Link>
      ),
    );
};

export const Sidebar: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const onSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchAuthSession();
        const idToken = res.tokens?.idToken?.payload;
        if (
          idToken &&
          Array.isArray(idToken['cognito:groups']) &&
          idToken['cognito:groups'].includes('admin')
        ) {
          setIsAdmin(true);
        }
      } catch (e) {}
    })();
  }, []);

  return (
    <Box className="bg-kc-primary h-full flex flex-col">
      <Box className="relative flex justify-center w-64 h-32">
        <Box className="absolute -top-10 w-64 h-32 bg-white rounded-tl-0 rounded-tr-0 rounded-br-[128px] rounded-bl-[128px]" />
        <img className="relative w-20 h-16 top-2" src={logo} alt="logo" />
      </Box>
      <Box className="flex flex-col px-4 gap-3 flex-1">
        <RenderRoutes
          navs={getRoutes().filter((nav) => nav.isAuthenticated)}
          isAdmin={isAdmin}
        />
      </Box>
      <Box className="p-4">
        <Button
          variant="text"
          className="text-white normal-case font-semibold px-4 hover:opacity-80"
          startIcon={<LogoutIcon className="rotate-180" />}
          onClick={onSignOut}
        >
          Sign Out
        </Button>
      </Box>
    </Box>
  );
};
