import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Sidebar } from './Sidebar';

export const SidebarMobile: React.FC<{}> = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        sx={{ ...(mobileOpen && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          PaperProps={{
            className: 'box-border w-screen flex flex-col relative',
          }}
        >
          <Sidebar />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className="absolute right-2 top-2 text-kc-grey bg-slate-200 rounded-lg opacity-50"
          >
            <CloseIcon />
          </IconButton>
        </Drawer>
      </nav>
    </>
  );
};
