import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import { Input } from '../../components/Input';
import { awsConfig } from '../auth/config';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const authUser = async (username: string, password: string) => {
  return new Promise((resolve, reject) => {
    var authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    var poolData = {
      UserPoolId: awsConfig.userPoolId, // Your user pool id here
      ClientId: awsConfig.userPoolClientId, // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: username,
      Pool: userPool,
      Storage: {
        setItem: () => null,
        getItem: () => null,
        removeItem: () => null,
        clear: () => null,
      },
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function () {
        resolve(null);
      },
      onFailure: function () {
        reject();
      },
    });
  });
};

export const AuthenticateUserDialog: React.FC<{
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ open, onSuccess, onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [credsInvalid, setCredsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      authUser(values.email, values.password)
        .then(() => {
          setCredsInvalid(false);
          setShowPassword(false);
          formik.resetForm();
          onSuccess();
        })
        .catch(() => {
          setCredsInvalid(true);
        })
        .finally(() => setIsLoading(false));
      setIsLoading(true);
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className="rounded-lg p-10">
        <Box className="mb-4 flex">
          <Typography className="text-black text-2xl font-semibold flex-1">
            Verify user
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box className="w-[500px]">
          <form onSubmit={formik.handleSubmit}>
            <Input
              name="email"
              label="Email"
              placeholder="xyz@gmail.com"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                formik.touched.email &&
                (Boolean(formik.errors.email) || credsInvalid)
              }
              errorMessage={formik.touched.email ? formik.errors.email : ''}
            />
            <Input
              name="password"
              label="Password"
              placeholder="••••••••"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.touched.password &&
                (Boolean(formik.errors.password) || credsInvalid)
              }
              errorMessage={
                formik.touched.password ? formik.errors.password : ''
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box className="flex mb-8">
              <Box className="flex-1 text-base text-kc-red font-semibold">
                {credsInvalid ? 'Invalid Credentials' : ''}
              </Box>
            </Box>
            <Button
              fullWidth
              type="submit"
              disableRipple
              disabled={isLoading}
              className="normal-case rounded-[32px] bg-kc-primary h-12 text-white font-semibold text-[19px] hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
            >
              {isLoading && (
                <CircularProgress size={20} className="text-kc-grey-4 mr-6" />
              )}
              Verify
            </Button>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};
