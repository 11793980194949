import DoneAllIcon from '@mui/icons-material/DoneAll';
import WestIcon from '@mui/icons-material/West';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Link,
  Typography,
} from '@mui/material';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import logo from '../../assets/logo.svg';
import { Input } from '../../components/Input';

import { NewPassword } from './components/NewPassword';
const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

const ForgotPaswordDialog: React.FC<{
  onBack: () => void;
  onNext: (email: string) => void;
}> = ({ onBack, onNext }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await resetPassword({ username: values.email });
        onNext(values.email);
      } catch (e) {
        setError(e.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (error) formik.setFieldError('email', error);
  }, [error]);

  return (
    <Box className="flex justify-center w-full items-center">
      <Box className="border-solid border-[1px] border-kc-grey-4 flex flex-col gap-4 p-10 rounded-2xl h-fit w-[600px] items-center">
        <img
          src="src/assets/forgot-password.png"
          className="w-12 h-12"
          alt="Forgot Password"
        />
        <Typography className="text-4xl font-bold text-kc-grey">
          Forget Password?
        </Typography>
        <Typography className="text-base font-medium text-kc-grey">
          No problem, we'll send you reset instructions
        </Typography>
        <Box className="w-full">
          <form onSubmit={formik.handleSubmit}>
            <Input
              name="email"
              label="Email"
              placeholder="xyz@gmail.com"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              errorMessage={formik.touched.email ? formik.errors.email : ''}
            />
            <Box className="flex flex-col gap-2 mt-2">
              <Button
                fullWidth
                type="submit"
                disableRipple
                disabled={isLoading}
                className="normal-case rounded-[32px] bg-kc-primary h-12 text-white font-semibold text-[19px] hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
              >
                {isLoading && (
                  <CircularProgress size={20} className="text-kc-grey-4 mr-6" />
                )}
                Reset Password
              </Button>
              <Button
                disableRipple
                onClick={onBack}
                className="normal-case text-xs text-kc-grey"
              >
                <WestIcon fontSize="small" className="mr-2" />
                Back
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

const VerificationCodeDialog: React.FC<{
  verificationCode: string;
  isInvalidCode: boolean;
  onBack: () => void;
  onNext: (verificationCode: string) => void;
}> = ({ onBack, onNext, isInvalidCode, verificationCode }) => {
  const [otp, setOtp] = useState(verificationCode);
  const [errorMessage, setErrorMessage] = useState(isInvalidCode);

  const onVerify = () => {
    if (otp.length < 6) setErrorMessage(true);
    else onNext(otp);
  };
  return (
    <Box className="border-solid border-[1px] border-kc-grey-4 flex flex-col gap-4 p-10 rounded-2xl h-fit w-[600px] items-center">
      <img
        src="src/assets/otp.png"
        className="w-12 h-12"
        alt="Forgot Password"
      />
      <Typography className="text-4xl font-bold text-kc-grey">
        Enter One Time Password
      </Typography>
      <Typography className="text-base font-medium text-kc-grey">
        We have sent you One Time Password to your email
      </Typography>
      <Box className="w-full">
        <Box className="my-8">
          <MuiOtpInput
            value={otp}
            onChange={setOtp}
            length={6}
            TextFieldsProps={{ error: errorMessage }}
          />
          <Box height={'20px'}>
            <FormHelperText
              error={errorMessage}
              className="mt-2"
              component={'div'}
            >
              {errorMessage && 'Enter a valid OTP'}
            </FormHelperText>
          </Box>
        </Box>
        <Box className="flex flex-col gap-2">
          <Button
            fullWidth
            type="submit"
            disableRipple
            onClick={onVerify}
            className="normal-case rounded-[32px] bg-kc-primary h-12 text-white font-semibold text-[19px] hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
          >
            Verify
          </Button>
          <Button
            disableRipple
            onClick={onBack}
            className="normal-case text-xs text-kc-grey"
          >
            <WestIcon fontSize="small" />
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const ResetSuccess: React.FC<{}> = () => {
  return (
    <Box className="border-solid border-[1px] border-kc-grey-4 flex flex-col gap-4 p-10 rounded-2xl h-fit w-[600px] items-center">
      <Box className="rounded-full p-2 bg-kc-primary text-white w-16 h-16 flex items-center justify-center">
        <DoneAllIcon className="text-5xl" />
      </Box>
      <Typography className="font-bold text-kc-grey text-2xl">
        Password reset successfully
      </Typography>
      <Link
        component={'a'}
        href="/login"
        className="flex items-center no-underline px-10 normal-case rounded-[32px] bg-kc-primary h-12 text-white font-semibold text-[19px] hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
      >
        Continue to Sign in
      </Link>
    </Box>
  );
};

export const ForgotPasword: React.FC<{}> = () => {
  const [step, setStep] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (newPassword: string) => {
    setIsLoading(true);
    try {
      await confirmResetPassword({
        confirmationCode: verificationCode,
        newPassword: newPassword,
        username: email,
      });
      setStep(3);
    } catch (err) {
      if ((err as { name: string }).name === 'CodeMismatchException') {
        setStep(1);
        setIsInvalidCode(true);
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="flex flex-col justify-center w-full items-center">
      <img className="relative w-28 h-24 top-2 mt-5" src={logo} alt="logo" />
      <Box className="flex justify-center w-full items-center flex-1">
        {step === 0 && (
          <ForgotPaswordDialog
            onBack={() => navigate('/login', { replace: true })}
            onNext={(email) => {
              setEmail(email);
              setStep(1);
            }}
          />
        )}
        {step === 1 && (
          <VerificationCodeDialog
            verificationCode={verificationCode}
            isInvalidCode={isInvalidCode}
            onBack={() => setStep(0)}
            onNext={(verificationCode) => {
              setVerificationCode(verificationCode);
              setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <NewPassword
            onNext={onChange}
            onBack={() => setStep(1)}
            isLoading={isLoading}
          />
        )}
        {step === 3 && <ResetSuccess />}
      </Box>
      <Box className="w-full border-solid border-t border-b-0 border-kc-grey text-kc-grey p-2 text-center">
        © Copyright KidzCubicle | All Rights Reserved.
      </Box>
    </Box>
  );
};
