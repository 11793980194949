import React from 'react';

import PromoCodeList from './PromoCodeList';

const PromoCode = () => {
  return (
    <div>
      <PromoCodeList />
    </div>
  );
};

export default PromoCode;
