import type { InputBaseProps } from '@mui/material';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
} from '@mui/material';

type InputProps = {
  label?: string;
  errorMessage?: string;
  error?: boolean;
};
export const Input: React.FC<InputProps & InputBaseProps> = ({
  label,
  defaultValue,
  value,
  onChange,
  disabled,
  error,
  errorMessage,
  required,
  ...rest
}) => {
  return (
    <FormControl
      fullWidth
      error={Boolean(error)}
      disabled={disabled}
      className="flex flex-col gap-[8px]"
      required={required}
    >
      {label && (
        <FormLabel
          sx={{
            '.MuiFormLabel-asterisk': { color: '#F39200' },
            '&.Mui-focused': { color: '#000' },
            '&.Mui-error': { color: '#d32f2f' },
          }}
          className="font-[600] text-[16px] text-black"
        >
          {label}
        </FormLabel>
      )}
      <InputBase
        name={rest.name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        sx={{
          height: '48px',
          padding: '14px 20px',
          boxShadow: '0px 0px 0px 1px #1B1F2326',
          borderRadius: '8px',
        }}
        inputProps={{ style: { color: '#000', fontWeight: 400 } }}
        {...rest}
      />
      <Box height={'20px'}>
        {errorMessage && (
          <FormHelperText className="m-0 absolute" component={'span'}>
            {errorMessage}
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};
