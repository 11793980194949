import { GetObjectCommand } from '@aws-sdk/client-s3';
import { fetchUserAttributes } from 'aws-amplify/auth';

import { awsConfig } from '../features/auth/config';

import { getS3Client } from './getS3Client';

export const getProfileImage = async (
  accountSubId?: string,
  profileId?: number,
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const attributes = await fetchUserAttributes();
      const sub = attributes.sub;

      const s3Client = await getS3Client();
      const params = {
        Bucket: profileId ? awsConfig.userBucket : awsConfig.bucket,
        Key: profileId
          ? `${accountSubId}/${profileId}/profile.jpg`
          : `${sub}/profile.jpg`,
        ResponseExpires: new Date(),
      };

      const command = new GetObjectCommand(params);
      const response = await s3Client.send(command);
      if (!response.Body) {
        throw new Error('Not found');
      }
      // Convert the response body to a Blob
      const bodyContents = await response.Body.transformToByteArray();
      const blob = new Blob([bodyContents], { type: response.ContentType });
      // Create a URL for the Blob
      const imageUrl = URL.createObjectURL(blob);
      resolve(imageUrl);
    } catch (e) {
      reject(e);
    }
  });
};
