import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Input } from '../../../components/Input';
import { Phone } from '../../../components/Phone';

import { useCreateuser } from './useCreateUser';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.object().shape({
    code: yup.string().required('Phone number is required'),
    number: yup
      .number()
      .typeError('Invalid phone number')
      .required('Phone number is required'),
  }),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  group: yup.string().required('Group is required'),
});

export const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: { code: '+44', number: '' },
  group: 'staff',
};
export const AddUser: React.FC<{
  openAddUser: boolean;
  mode: 'CREATE' | 'EDIT';
  handleCloseAddUser: () => void;
  onSuccess: () => void;
  initialValues?: typeof defaultValues;
}> = ({ openAddUser, mode, handleCloseAddUser, onSuccess, initialValues }) => {
  const [createUser, { isLoading }] = useCreateuser(mode);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.validateForm(values);
      createUser({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone.code + values.phone.number,
        email: values.email,
        group: values.group,
      })
        .then(() => {
          setOpen(true);
          onSuccess();
        })
        .catch((_) => {
          setErrorMessage('Error creating user');
        });
    },
  });

  useEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  return (
    <Dialog
      open={openAddUser}
      onClose={handleCloseAddUser}
      PaperProps={{ className: 'w-2/4' }}
    >
      <DialogTitle className="text-3xl text-kc-grey font-bold">
        {mode === 'CREATE' ? 'Add New User' : 'Update User'}
      </DialogTitle>
      <DialogContent className="py-5">
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <Typography className="text-4xl text-kc-grey font-bold mb-4"></Typography>
          <Stack direction={'row'} gap={2}>
            <Input
              name="firstName"
              label="Firstname"
              placeholder="Arthur"
              fullWidth
              required
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              errorMessage={
                formik.touched.firstName ? formik.errors.firstName : ''
              }
            />
            <Input
              name="lastName"
              label="Lastname"
              placeholder="Duncan"
              fullWidth
              required
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              errorMessage={
                formik.touched.lastName ? formik.errors.lastName : ''
              }
            />
          </Stack>
          <Input
            name="email"
            label="Email"
            placeholder="xyz@gmail.com"
            fullWidth
            required
            value={formik.values.email}
            disabled={mode === 'EDIT'}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            errorMessage={formik.touched.email ? formik.errors.email : ''}
          />
          <Phone
            name="phone"
            label="Phone Number"
            placeholder="123456789"
            fullWidth
            required
            value={formik.values.phone}
            onChange={(value) => {
              formik.setFieldValue('phone', value);
              formik.setTouched({ phone: { code: true, number: true } });
            }}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            errorMessage={
              formik.touched.phone?.number ? formik.errors.phone?.number : ''
            }
          />
          <FormControl
            fullWidth
            className="flex flex-col gap-[8px] mb-7"
            required={true}
          >
            <FormLabel
              sx={{
                '.MuiFormLabel-asterisk': { color: '#F39200' },
                '&.Mui-focused': { color: '#000' },
                '&.Mui-error': { color: '#d32f2f' },
              }}
              className="font-[600] text-[16px] text-black"
            >
              Role
            </FormLabel>
            <RadioGroup
              row
              name="group"
              value={formik.values.group}
              onChange={formik.handleChange}
              className="gap-4"
            >
              <FormControlLabel
                className={clsx(
                  'm-0 text-kc-grey text-base py-3 px-5 border-solid border-kc-grey-4 rounded-[60px]',
                  {
                    'border-kc-primary': formik.values.group === 'staff',
                    'border-kc-grey-4': formik.values.group !== 'staff',
                  },
                )}
                value="staff"
                control={<Radio className="hidden" />}
                label="Staff"
              />
              <FormControlLabel
                className={clsx(
                  'm-0 text-kc-grey text-base py-3 px-5 border-solid border-kc-grey-4 rounded-[60px]',
                  {
                    'border-kc-primary': formik.values.group === 'admin',
                    'border-kc-grey-4': formik.values.group !== 'admin',
                  },
                )}
                value="admin"
                control={<Radio className="hidden" />}
                label="Admin"
              />
            </RadioGroup>
          </FormControl>
          {errorMessage ? (
            <Typography className="text-kc-red font-medium mb-4">
              {errorMessage}
            </Typography>
          ) : null}
          <Stack direction={'row'}>
            <Box className="flex-1"></Box>
            <Stack direction="row" gap={1}>
              <Button
                disableRipple
                disabled={isLoading}
                onClick={handleCloseAddUser}
                className="normal-case rounded-[32px] bg-white text-kc-grey border-solid border-kc-primary border-2 font-semibold hover:bg-kc-white hover:text-kc-grey hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 w-32 h-12"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disableRipple
                disabled={isLoading}
                className="normal-case rounded-[32px] bg-kc-primary text-white font-semibold hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 w-32 h-12"
              >
                {isLoading && (
                  <CircularProgress size={16} className="text-kc-grey-4 mr-6" />
                )}
                {mode === 'CREATE' ? 'Submit' : 'Update'}
              </Button>
            </Stack>
          </Stack>
        </form>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            handleCloseAddUser();
          }}
        >
          <Box className="w-80 h-64 flex flex-col justify-center items-center gap-3">
            <Box className="w-20 h-20 bg-kc-primary rounded-full flex items-center justify-center">
              <DoneAllIcon className="text-white text-5xl" />
            </Box>
            <Typography className="font-bold text-kc-grey text-2xl">
              {mode === 'CREATE' ? 'User added!' : 'User updated!'}
            </Typography>
          </Box>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};
