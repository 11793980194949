import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, IconButton } from '@mui/material';
import type { ChangeEvent } from 'react';
export const ProfilePicSelector: React.FC<{
  name: string;
  value?: string;
  onChange: (val: string) => void;
}> = ({ name, value, onChange }) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newImage = event.target?.files?.[0];
    if (newImage) {
      if (value) {
        URL.revokeObjectURL(value);
      }
      onChange(URL.createObjectURL(newImage));
    }
  };

  return (
    <Box className="rounded-full relative mb-7">
      <Avatar src={value} className=" w-28 h-28" />
      <IconButton
        className="rounded-full bg-kc-grey-5 hover:bg-kc-grey-5 hover:bg-opacity-90 absolute right-2 bottom-0"
        size="small"
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={'profile-pic-selector-button'}
          className="cursor-pointer"
        >
          <EditIcon className="flex" />
          <input
            id="profile-pic-selector-button"
            name={name}
            accept="image/*"
            type="file"
            hidden
            onChange={handleOnChange}
          />
        </label>
      </IconButton>
    </Box>
  );
};
