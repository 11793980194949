import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';

import { useGetPromoCodeListQuery } from '../../../services/promo_code';
import type { RootState } from '../../../store';
import { useAppSelector } from '../../../store';
import formatDate from '../../../utils/formatDate';

import AddPromoCode from './AddPromoCode';

const PromoCodeList = () => {
  const { data, isLoading, refetch } = useGetPromoCodeListQuery();
  const globalSearchText = useAppSelector(
    (state: RootState) => state.search.searchText,
  );
  const [isAddPackage, setIsPackage] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [promoCodeList, setPromoCodeList] = useState([]);

  const handleEditClick = (row: any) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };
  useEffect(() => {
    if (data) setPromoCodeList(data);
  }, [data]);

  useEffect(() => {
    if (!globalSearchText) {
      setPromoCodeList(data);
      return;
    }

    const searchLowercase = globalSearchText.toLowerCase();

    const filtered = data?.filter((promo: any) => {
      return (
        promo.promoCode?.toLowerCase().includes(searchLowercase) ||
        promo.promoDescription?.toLowerCase().includes(searchLowercase)
      );
    });

    setPromoCodeList(filtered);
  }, [globalSearchText]);

  return (
    <Box>
      <AddPromoCode
        open={isAddPackage}
        handleClose={() => setIsPackage(false)}
        isEditMode={false}
        refetch={refetch}
      />
      <AddPromoCode
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        isEditMode={true}
        rowData={selectedRow}
        refetch={refetch}
      />

      <TableContainer component={Paper}>
        <Toolbar className="bg-white rounded-ss-lg rounded-se-lg shadow-kc-input flex">
          <Typography className="text-lg font-semibold text-kc-grey flex-1">{`Promo Codes (${promoCodeList?.length || 0})`}</Typography>
          <Button
            className="bg-kc-primary normal-case rounded-[32px] text-white px-4 py-2 font-semibold hover:opacity-75 hover:bg-kc-primary"
            onClick={() => setIsPackage(true)}
          >
            + Add Promo Code
          </Button>
        </Toolbar>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow className="bg-kc-normal-blue text-white h-20">
              <TableCell className="text-white text-base font-semibold">
                No
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Promo Code
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Description
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Validity
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Minutes
              </TableCell>

              <TableCell className="text-white text-base font-semibold">
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              promoCodeList?.length > 0 &&
              promoCodeList?.map((row: any, index: number) => (
                <TableRow key={row.id} className="even:bg-kc-light-blue-3 h-20">
                  <TableCell className="text-base">{index + 1}</TableCell>
                  <TableCell className="text-base">{row.promoCode}</TableCell>
                  <TableCell className="text-base">
                    {row.promoDescription}
                  </TableCell>
                  <TableCell className="text-base">
                    {formatDate(row.expiryDate, 'DD/MM/YYYY')}
                  </TableCell>
                  <TableCell className="text-base">
                    {row.promoMinutes}
                  </TableCell>

                  <TableCell className="text-base">
                    <IconButton
                      aria-label="Edit"
                      onClick={() => handleEditClick(row)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {!isLoading && promoCodeList?.length <= 0 && data && (
              <TableRow className="odd:bg-kc-light-blue-3 h-20">
                <TableCell colSpan={6} className="text-center">
                  Promo Code Not Found
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <TableRow className="odd:bg-kc-light-blue-3 h-20">
                <TableCell colSpan={5}>
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PromoCodeList;
