import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createBrowserHistory } from 'history';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { bookingApi } from '../services/booking';
import { checkinApi } from '../services/checkin';
import { packageApi } from '../services/package';
import { promoCodeApi } from '../services/promo_code';

import checkinReducer from './checkinSlice';
import searchReducer from './searchSlice';

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });
export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      checkinApi.middleware,
      bookingApi.middleware,
      promoCodeApi.middleware,
      packageApi.middleware,
      routerMiddleware,
    ]),
  reducer: combineReducers({
    router: routerReducer,
    checkin: checkinReducer,
    search: searchReducer,
    [checkinApi.reducerPath]: checkinApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [packageApi.reducerPath]: packageApi.reducer,
    [promoCodeApi.reducerPath]: promoCodeApi.reducer,
  }),
});

setupListeners(store.dispatch);
export const history = createReduxHistory(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
