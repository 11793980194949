import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

import {
  useGetSessionsQuery,
  usePostMoveUserToWaitingMutation,
  usePostStaffCheckoutMutation,
  usePostUserCheckinMutation,
} from '../../services/checkin';
import type { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store';
import { removeScanUser } from '../../store/checkinSlice';

import { AuthenticateUserDialog } from './AuthenticateUserDialog';
import { ChildProfileCard } from './ChildProfileCard';

export const CheckinList: React.FC<{ listType: string }> = ({ listType }) => {
  const [endSessionId, setEndSessionId] = useState<number | null>(null);
  const [endSessionStep, setEndSessionStep] = useState<number | null>(null);

  const scannedUsers = useAppSelector(
    (state: RootState) => state.checkin.scannedUsers,
  );
  const globalSearchText = useAppSelector(
    (state: RootState) => state.search.searchText,
  );
  const dispatch = useAppDispatch();

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchSessions,
  } = useGetSessionsQuery();

  const [userCheckinMutation, { isLoading: isCheckining }] =
    usePostUserCheckinMutation();

  const [moveToWaitingAreaMutations, { isLoading: movingToWaiting }] =
    usePostMoveUserToWaitingMutation();

  const [staffCheckoutMutatin, { isLoading: staffCheckingout }] =
    usePostStaffCheckoutMutation();

  const onCheckinUser = (id: number) => {
    const user = scannedUsers.find((u) => u.profile_id === id);
    if (user) {
      userCheckinMutation({
        barcode: user?.barcode,
        profileId: user?.profile_id,
        accountId: user?.account_id,
        availableMinutes: user?.minutes,
        checkinTime: new Date().toISOString(),
        staffId: 0,
      }).then(() => {
        refetchSessions().finally(() => {
          dispatch(removeScanUser({ id }));
        });
      });
    }
  };

  const onMoveToWaitingArea = (moveToWaitingId: number | null) => {
    if (moveToWaitingId) {
      moveToWaitingAreaMutations({
        profileId: moveToWaitingId,
        sessionStatus: 'IDLE',
      }).then(() => {
        refetchSessions();
      });
    }
  };

  const onStaffCheckout = () => {
    if (endSessionId) {
      staffCheckoutMutatin({ profileId: endSessionId, staffId: 1234 }).then(
        () => {
          refetchSessions().finally(() => {
            setEndSessionId(null);
            setEndSessionStep(null);
          });
        },
      );
    }
  };

  return (
    <>
      <Box className="flex gap-3 flex-wrap content-start">
        {scannedUsers?.length === 0 && data?.length === 0 && isLoading && (
          <Box className="w-full h-full flex items-center justify-center">
            <CircularProgress />
          </Box>
        )}
        {listType === 'active'
          ? scannedUsers
              .filter(
                (d) =>
                  !globalSearchText ||
                  d.profile_first_name
                    .toLowerCase()
                    .includes(globalSearchText.toLowerCase()) ||
                  d.profile_last_name
                    .toLowerCase()
                    .includes(globalSearchText.toLowerCase()),
              )
              .map((d) => (
                <ChildProfileCard
                  key={d.profile_id}
                  id={d.profile_id}
                  subId={d.sub_id}
                  accountId={d.account_id}
                  name={`${d.profile_first_name} ${d.profile_last_name}`}
                  age={d.age}
                  contactNumber={d.phone_number}
                  alternateNumber={d.alternate_emergency_no}
                  firstAidInfo={d.first_aid_info}
                  parentName={d.first_name}
                  parentLastName={d.last_name}
                  availableMinutes={d.minutes}
                  onStartSession={onCheckinUser}
                  startingSession={isCheckining || isFetching}
                  picture={d.picture}
                />
              ))
          : null}
        {data
          ?.filter(
            (d) =>
              (d.sessionStatus === 'ACTIVE' && listType === 'active') ||
              (d.sessionStatus === 'IDLE' && listType === 'waiting'),
          )
          .filter(
            (d) =>
              !globalSearchText ||
              d.profileFirstName
                .toLowerCase()
                .includes(globalSearchText.toLowerCase()) ||
              d.profileLastName
                .toLowerCase()
                .includes(globalSearchText.toLowerCase()),
          )
          .map((d) => (
            <ChildProfileCard
              key={d.profileId}
              id={d.profileId}
              name={`${d.profileFirstName} ${d.profileLastName}`}
              age={dayjs().diff(d.dob, 'year')}
              contactNumber={d.phoneNumber}
              alternateNumber={d.alternateEmergencyNo}
              firstAidInfo={d.firstAidInfo}
              parentName={d.accountFirstName}
              parentLastName={d.accountLastName}
              checkinTime={d.checkinTime}
              estimatedEndTime={d.estimatedEndTime}
              sessionStatus={d.sessionStatus}
              onEndSession={(id) => {
                setEndSessionId(id);
                setEndSessionStep(0);
              }}
              onMoveToWaiting={onMoveToWaitingArea}
              picture={d.picture}
              movingToWaiting={movingToWaiting}
            />
          ))}
      </Box>
      <Dialog
        onClose={() => {
          setEndSessionId(null);
          setEndSessionStep(null);
        }}
        open={Boolean(endSessionId) && endSessionStep === 0}
      >
        <Box className="rounded-lg p-10">
          <Box className="flex flex-col gap-10">
            <Typography className="text-2xl text-kc-grey font-medium">
              Do you really want to end the session?
            </Typography>
            <Box className="flex gap-4">
              <Button
                className="h-11 w-56 text-white text-base font-semibold bg-kc-red hover:bg-kc-red hover:opacity-90 rounded-[32px] normal-case disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
                onClick={() => setEndSessionStep(1)}
                disabled={staffCheckingout || isFetching}
              >
                {(staffCheckingout || isFetching) && (
                  <CircularProgress className="text-kc-grey-4 mr-2" size={24} />
                )}
                End
              </Button>
              <Button
                className="h-11 w-56 text-kc-red text-base font-semibold bg-white hover:bg-white hover:opacity-90 rounded-[32px] box-border border-kc-red border-solid border-[1px] normal-case disabled:bg-kc-grey-12 disabled:text-kc-grey-4 disabled:border-kc-grey-4"
                onClick={() => {
                  setEndSessionId(null);
                  setEndSessionStep(null);
                }}
                disabled={staffCheckingout || isFetching}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <AuthenticateUserDialog
        open={Boolean(endSessionId) && endSessionStep === 1}
        onSuccess={onStaffCheckout}
        onClose={() => {
          setEndSessionId(null);
          setEndSessionStep(null);
        }}
      />
    </>
  );
};
