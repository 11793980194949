import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ky from 'ky';

type BookingForDateRes = Array<{
  booking: {
    bookingId: number;
    accountId: number;
    profileId: number;
    sessionId: number;
    slotsBooked: number;
    sessionTime: string;
    date: string;
    status?: string;
  };
  profileDetails: {
    profileFirstName: string;
    dob: string;
  };
}>;
export const bookingApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_SERVICE_URL}/uaps/api/v1`,
    fetchFn: async (...args) => ky(...args),
    // prepareHeaders: async (headers) => {
    //   try {
    //     const res = await fetchAuthSession();
    //     if (!res.userSub) throw new Error('Unauthenticated');
    //     const token = res.tokens?.idToken?.toString();
    //     headers.set('Authorization', `Bearer ${token}`);
    //   } catch (e) {}
    //   return headers;
    // },
  }),

  endpoints: (builder) => ({
    getBookingForDate: builder.query<BookingForDateRes, { date: string }>({
      query: ({ date }) => `/bookings/date/bp/${date}`,
    }),
  }),

  reducerPath: 'bookingApi',
});

export const { useGetBookingForDateQuery } = bookingApi;
