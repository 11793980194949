import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ScanRes } from '../services/checkin';

type initialStateType = {
  scannedUsers: Array<ScanRes>;
};
const initialState: initialStateType = {
  scannedUsers: [],
};

export const checkinSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    addScanUser: (state, action: PayloadAction<ScanRes>) => {
      state.scannedUsers.push(action.payload);
    },
    removeScanUser: (state, action: PayloadAction<{ id: number }>) => {
      state.scannedUsers = state.scannedUsers.filter(
        (p) => p.profile_id !== action.payload.id,
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { addScanUser, removeScanUser } = checkinSlice.actions;

export default checkinSlice.reducer;
