import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';

import { awsConfig } from './features/auth/config';
import { AppRoutes } from './routes';
import { history, store } from './store';
import theme from './theme';

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: awsConfig.identityPoolId,
      userPoolClientId: awsConfig.userPoolClientId,
      userPoolId: awsConfig.userPoolId,
    },
  },
});

const App: React.FC = () => {
  return (
    <ReduxStoreProvider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <HistoryRouter history={history}>
              <Box className="flex w-full min-h-screen">
                <AppRoutes />
              </Box>
            </HistoryRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReduxStoreProvider>
  );
};

export default App;
