import { createTheme } from '@mui/material';

import DayPicker from './themeComponents/datePicker';

const theme = createTheme({
  components: {
    MuiPickersDay: DayPicker,
  },
});

export default theme;
