import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';

import { getCodeAndNumber } from '../../../components/Phone';
import type { RootState } from '../../../store';
import { useAppSelector } from '../../../store';

import type { defaultValues } from './AddUser';
import { AddUser } from './AddUser';
import { useActivateUser } from './useActivateUser';
import type { UsersType } from './useListUsers';
import { useListUsers } from './useListUsers';

export const ListUsers = () => {
  const [mode, setMode] = useState<'CREATE' | 'EDIT' | null>(null);
  const { users: data, isLoading, refetch } = useListUsers();
  const [editUser, setEditUser] = useState<typeof defaultValues>();
  const [selectedRow, setSelectedRow] = useState<UsersType[0]>();
  const [userList, setUserList] = useState<any>([]);
  const [activateUser] = useActivateUser();
  const globalSearchText = useAppSelector(
    (state: RootState) => state.search.searchText,
  );

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorMenuEl);
  const handleMenuClick =
    (row: UsersType[0]) => (event: React.MouseEvent<HTMLElement>) => {
      setSelectedRow(row);
      setAnchorMenuEl(event.currentTarget);
    };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const onEditClick = () => {
    if (selectedRow) {
      setEditUser({
        ...selectedRow,
        phone: {
          code: getCodeAndNumber(selectedRow.phone || '').code || '',
          number: getCodeAndNumber(selectedRow.phone || '').number || '',
        },
      });
      setMode('EDIT');
    }
    handleMenuClose();
  };

  const onActivateClick = () => {
    if (selectedRow) {
      activateUser(selectedRow?.email, !selectedRow?.status).then(() => {
        refetch();
      });
    }
    handleMenuClose();
  };

  useEffect(() => {
    if (data) setUserList(data);
  }, [data]);

  useEffect(() => {
    if (!globalSearchText) {
      setUserList(data);
      return;
    }

    const searchLowercase = globalSearchText.toLowerCase();

    const filtered = data?.filter((user: any) => {
      return (
        user.firstName?.toLowerCase().includes(searchLowercase) ||
        user.lastName?.toLowerCase().includes(searchLowercase) ||
        user.email?.toLowerCase().includes(searchLowercase)
      );
    });

    setUserList(filtered);
  }, [globalSearchText]);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Toolbar className="bg-white rounded-ss-lg rounded-se-lg shadow-kc-input flex">
          <Typography className="text-lg font-semibold text-kc-grey flex-1">{`Users (${data?.length || 0})`}</Typography>
          <Button
            className="bg-kc-primary normal-case rounded-[32px] text-white px-4 py-2 font-semibold hover:opacity-75 hover:bg-kc-primary"
            onClick={() => setMode('CREATE')}
          >
            + Add User
          </Button>
        </Toolbar>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow className="bg-kc-normal-blue text-white h-20">
              <TableCell className="text-white text-base font-semibold">
                Name
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Email
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Role
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Status
              </TableCell>
              <TableCell className="text-white text-base font-semibold">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              userList?.length > 0 &&
              userList.map((row: any) => (
                <TableRow
                  key={row.email}
                  className="even:bg-kc-light-blue-3 h-20"
                >
                  <TableCell className="text-base">{`${row.firstName} ${row.lastName}`}</TableCell>

                  <TableCell className="text-base">{row.email}</TableCell>

                  <TableCell className="text-base">
                    <Box className="bg-kc-normal-blue text-white py-1 px-4 font-medium rounded-[60px] w-fit">
                      {row.group}
                    </Box>
                  </TableCell>

                  <TableCell className="text-base">
                    {row.status ? (
                      <Box className="rounded bg-kc-primary font-medium text-white py-1 px-4 w-fit">
                        Active
                      </Box>
                    ) : (
                      <Box className="rounded bg-kc-red font-medium text-white py-1 px-4 w-fit">
                        Inactive
                      </Box>
                    )}
                  </TableCell>

                  <TableCell className="text-base">
                    <IconButton onClick={handleMenuClick(row)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

            {!isLoading && userList?.length <= 0 && data && (
              <TableRow className="odd:bg-kc-light-blue-3 h-20">
                <TableCell colSpan={5} className="text-center">
                  User Not Found
                </TableCell>
              </TableRow>
            )}

            {isLoading && (
              <TableRow className="odd:bg-kc-light-blue-3 h-20">
                <TableCell colSpan={5}>
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={anchorMenuEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={openMenu}
        onClose={handleMenuClose}
        PaperProps={{ sx: { width: '110px' } }}
      >
        <MenuItem onClick={onEditClick}>Edit</MenuItem>
        <MenuItem onClick={onActivateClick}>
          {selectedRow?.status ? 'Inactivate' : 'Activate'}
        </MenuItem>
      </Menu>
      {mode && (
        <AddUser
          openAddUser={Boolean(mode)}
          mode={mode as 'CREATE' | 'EDIT'}
          handleCloseAddUser={() => {
            setMode(null);
            setEditUser(undefined);
          }}
          onSuccess={() => {
            refetch();
            setEditUser(undefined);
          }}
          initialValues={editUser}
        />
      )}
    </Box>
  );
};
