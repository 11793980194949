import React from 'react';

import PackageList from './PackageList';

const Packages = () => {
  return (
    <div>
      <PackageList />
    </div>
  );
};

export default Packages;
