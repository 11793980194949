import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import type { InputBaseProps } from '@mui/material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useState } from 'react';

export const COUNTRY_CODES = [
  { label: 'United States', value: '+1', flag: '🇺🇸' },
  { label: 'United Kingdom', value: '+44', flag: '🇬🇧' },
  { label: 'India', value: '+91', flag: '🇮🇳' },
  { label: 'Germany', value: '+49', flag: '🇩🇪' },
  { label: 'France', value: '+33', flag: '🇫🇷' },
  { label: 'China', value: '+86', flag: '🇨🇳' },
  { label: 'Japan', value: '+81', flag: '🇯🇵' },
  { label: 'Australia', value: '+61', flag: '🇦🇺' },
  { label: 'Canada', value: '+1', flag: '🇨🇦' },
  { label: 'Brazil', value: '+55', flag: '🇧🇷' },
  { label: 'Mexico', value: '+52', flag: '🇲🇽' },
  { label: 'Spain', value: '+34', flag: '🇪🇸' },
  { label: 'Italy', value: '+39', flag: '🇮🇹' },
  { label: 'Russia', value: '+7', flag: '🇷🇺' },
  { label: 'South Korea', value: '+82', flag: '🇰🇷' },
  { label: 'Netherlands', value: '+31', flag: '🇳🇱' },
  { label: 'Turkey', value: '+90', flag: '🇹🇷' },
  { label: 'Switzerland', value: '+41', flag: '🇨🇭' },
  { label: 'Sweden', value: '+46', flag: '🇸🇪' },
  { label: 'Norway', value: '+47', flag: '🇳🇴' },
  { label: 'Denmark', value: '+45', flag: '🇩🇰' },
  { label: 'Finland', value: '+358', flag: '🇫🇮' },
  { label: 'Poland', value: '+48', flag: '🇵🇱' },
  { label: 'Belgium', value: '+32', flag: '🇧🇪' },
  { label: 'Austria', value: '+43', flag: '🇦🇹' },
  { label: 'Greece', value: '+30', flag: '🇬🇷' },
  { label: 'Portugal', value: '+351', flag: '🇵🇹' },
  { label: 'Ireland', value: '+353', flag: '🇮🇪' },
  { label: 'Czech Republic', value: '+420', flag: '🇨🇿' },
  { label: 'Hungary', value: '+36', flag: '🇭🇺' },
  { label: 'Romania', value: '+40', flag: '🇷🇴' },
  { label: 'Argentina', value: '+54', flag: '🇦🇷' },
  { label: 'Chile', value: '+56', flag: '🇨🇱' },
  { label: 'Colombia', value: '+57', flag: '🇨🇴' },
  { label: 'Peru', value: '+51', flag: '🇵🇪' },
  { label: 'Venezuela', value: '+58', flag: '🇻🇪' },
  { label: 'Ecuador', value: '+593', flag: '🇪🇨' },
  { label: 'Bolivia', value: '+591', flag: '🇧🇴' },
  { label: 'Uruguay', value: '+598', flag: '🇺🇾' },
  { label: 'Paraguay', value: '+595', flag: '🇵🇾' },
  { label: 'New Zealand', value: '+64', flag: '🇳🇿' },
  { label: 'South Africa', value: '+27', flag: '🇿🇦' },
  { label: 'Egypt', value: '+20', flag: '🇪🇬' },
  { label: 'Saudi Arabia', value: '+966', flag: '🇸🇦' },
  { label: 'United Arab Emirates', value: '+971', flag: '🇦🇪' },
  { label: 'Qatar', value: '+974', flag: '🇶🇦' },
  { label: 'Kuwait', value: '+965', flag: '🇰🇼' },
  { label: 'Singapore', value: '+65', flag: '🇸🇬' },
  { label: 'Malaysia', value: '+60', flag: '🇲🇾' },
  { label: 'Thailand', value: '+66', flag: '🇹🇭' },
  { label: 'Indonesia', value: '+62', flag: '🇮🇩' },
  { label: 'Philippines', value: '+63', flag: '🇵🇭' },
  { label: 'Vietnam', value: '+84', flag: '🇻🇳' },
];

export const getCodeAndNumber = (
  phoneNumber: string,
): { code: string; number: string } => {
  const codes = COUNTRY_CODES.map((c) => c.value).sort(
    (a, b) => b.length - a.length,
  );
  for (const code of codes) {
    if (phoneNumber.startsWith(code)) {
      return {
        code,
        number: phoneNumber.slice(code.length),
      };
    }
  }
  return { code: codes[0], number: '' };
};

type InputProps = {
  label?: string;
  errorMessage?: string;
  error?: boolean;
  value: {
    code?: string;
    number?: string;
  };
  onChange: (value: { code?: string; number?: string }) => void;
};

export const Phone: React.FC<InputProps & InputBaseProps> = ({
  label,
  defaultValue,
  value,
  onChange,
  disabled,
  error,
  errorMessage,
  required,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedCode = COUNTRY_CODES.find((c) => c.value === value.code);

  const onSelectCode = (code: string) => () => {
    onChange({ code, number: value.number });
    handleClose();
  };

  return (
    <FormControl
      fullWidth
      error={Boolean(error)}
      disabled={disabled}
      className="flex flex-col gap-[8px]"
      required={required}
    >
      {label && (
        <FormLabel
          sx={{
            '.MuiFormLabel-asterisk': { color: '#F39200' },
            '&.Mui-focused': { color: '#000' },
            '&.Mui-error': { color: '#d32f2f' },
          }}
          className="font-[600] text-[16px] text-black"
        >
          {label}
        </FormLabel>
      )}
      <Stack direction={'row'} gap={1}>
        <Button
          disableRipple
          className="flex items-center justify-center text-base h-12 py-[14px] px-[20px] text-black font-normal shadow-kc-input-2 rounded-lg"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          <span className="text-lg mr-2">{selectedCode?.flag}</span>{' '}
          <span>{selectedCode?.value}</span>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PopoverClasses={{
            root: 'm-0 p-0',
            paper:
              'h-[420px] overflow-auto mt-2 shadow-none border-[1px] border-kc-grey-5 border-solid rounded-lg',
          }}
        >
          {COUNTRY_CODES.map((code) => (
            <MenuItem
              onClick={onSelectCode(code.value)}
              className="border-b-[1px] border-kc-grey-5 border-solid last:border-none"
            >
              <span className="text-2xl mr-2">{code.flag}</span>
              <span className="text-lg font-medium text-kc-grey">{`${code.label}: ${code.value}`}</span>
            </MenuItem>
          ))}
        </Menu>
        <InputBase
          name={rest.name}
          value={value.number}
          onChange={(e) =>
            onChange({ code: value.code, number: e.target.value })
          }
          disabled={disabled}
          defaultValue={defaultValue}
          sx={{
            height: '48px',
            padding: '14px 20px',
            boxShadow: '0px 0px 0px 1px #1B1F2326',
            borderRadius: '8px',
          }}
          inputProps={{ style: { color: '#000', fontWeight: 400 } }}
          {...rest}
        />
      </Stack>
      <Box height={'20px'}>
        {errorMessage && (
          <FormHelperText className="m-0 absolute" component={'span'}>
            {errorMessage}
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};
