import type {
  UserType,
  AdminCreateUserCommandInput,
  AdminUpdateUserAttributesCommandInput,
  AdminAddUserToGroupCommandInput,
  AdminRemoveUserFromGroupCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  AdminCreateUserCommand,
  AdminAddUserToGroupCommand,
  AdminRemoveUserFromGroupCommand,
  AdminUpdateUserAttributesCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { useCallback, useState } from 'react';

import { getCognitoIdentityProviderClient } from '../../../utils/getCognitoIdentityProviderClient';
import { awsConfig } from '../../auth/config';

export const useCreateuser = (
  mode: string = 'CREATE',
): [
  (user: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    group: string;
  }) => Promise<unknown>,
  {
    user: UserType | undefined;
    isLoading: boolean;
    error: unknown;
  },
] => {
  const [user] = useState<UserType | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const create = useCallback(
    async (user: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      group: string;
    }) => {
      return new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const identityProviderClient =
            await getCognitoIdentityProviderClient();

          let adminCreateUpdateUserResult = null;

          // TODO Need to optimise this entire piece of code
          if (mode === 'CREATE') {
            // Create user
            const adminCreateUserInput: AdminCreateUserCommandInput = {
              UserPoolId: awsConfig.userPoolId,
              Username: user.email,
              UserAttributes: [
                {
                  Name: 'name',
                  Value: user.firstName,
                },
                {
                  Name: 'family_name',
                  Value: user.lastName,
                },
                {
                  Name: 'phone_number',
                  Value: user.phone,
                },
                {
                  Name: 'email',
                  Value: user.email,
                },
                {
                  Name: 'email_verified',
                  Value: 'true',
                },
              ],
              DesiredDeliveryMediums: ['EMAIL'],
              ForceAliasCreation: false,
            };
            const adminCreateUserCommand = new AdminCreateUserCommand(
              adminCreateUserInput,
            );
            adminCreateUpdateUserResult = await identityProviderClient.send(
              adminCreateUserCommand,
            );

            if (!adminCreateUpdateUserResult.User) {
              throw new Error('User not found');
            }

            // Add to group
            const adminAddUserToGroupInput: AdminAddUserToGroupCommandInput = {
              UserPoolId: awsConfig.userPoolId,
              Username: user.email,
              GroupName: user.group,
            };
            const adminAddUserToGroupCommand = new AdminAddUserToGroupCommand(
              adminAddUserToGroupInput,
            );
            await identityProviderClient.send(adminAddUserToGroupCommand);
          }
          if (mode === 'EDIT') {
            // Update user
            const adminUpdateUserInput: AdminUpdateUserAttributesCommandInput =
              {
                UserPoolId: awsConfig.userPoolId,
                Username: user.email,
                UserAttributes: [
                  {
                    Name: 'name',
                    Value: user.firstName,
                  },
                  {
                    Name: 'family_name',
                    Value: user.lastName,
                  },
                  {
                    Name: 'phone_number',
                    Value: user.phone,
                  },
                  {
                    Name: 'email',
                    Value: user.email,
                  },
                  {
                    Name: 'email_verified',
                    Value: 'true',
                  },
                ],
              };
            const adminCreateUserCommand = new AdminUpdateUserAttributesCommand(
              adminUpdateUserInput,
            );
            adminCreateUpdateUserResult = await identityProviderClient.send(
              adminCreateUserCommand,
            );

            if (!adminCreateUpdateUserResult) {
              throw new Error('User not found');
            }

            // Remove from group
            const adminRemoveUserToGroupInput: AdminRemoveUserFromGroupCommandInput =
              {
                UserPoolId: awsConfig.userPoolId,
                Username: user.email,
                GroupName: user.group === 'admin' ? 'staff' : 'admin',
              };
            const adminRemoveUserToGroupCommand =
              new AdminRemoveUserFromGroupCommand(adminRemoveUserToGroupInput);
            await identityProviderClient.send(adminRemoveUserToGroupCommand);

            // Add to group
            const adminAddUserToGroupInput: AdminAddUserToGroupCommandInput = {
              UserPoolId: awsConfig.userPoolId,
              Username: user.email,
              GroupName: user.group,
            };
            const adminAddUserToGroupCommand = new AdminAddUserToGroupCommand(
              adminAddUserToGroupInput,
            );
            await identityProviderClient.send(adminAddUserToGroupCommand);
          }

          resolve(null);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          setError(e);
          reject(e);
        }
      });
    },
    [user, mode],
  );

  return [create, { user, isLoading, error }];
};
