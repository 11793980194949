import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';
import { fetchAuthSession } from 'aws-amplify/auth';

import { awsConfig } from '../features/auth/config';

const region = awsConfig.region;
const identityPoolId = awsConfig.identityPoolId;
const userPoolId = awsConfig.userPoolId;

export const getS3Client = async (): Promise<S3Client> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetchAuthSession();
      const idToken = res.tokens?.idToken?.toString();

      if (!idToken) throw new Error('Token not found');

      const client = new CognitoIdentityClient({ region });
      const commandGetIdCommand = new GetIdCommand({
        IdentityPoolId: identityPoolId,
        Logins: {
          [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
        },
      });
      const responseGetIdCommand = await client.send(commandGetIdCommand);
      const identityId = responseGetIdCommand.IdentityId;

      const commandGetCredentialsForIdentityCommand =
        new GetCredentialsForIdentityCommand({
          IdentityId: identityId,
          Logins: {
            [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
          },
        });
      const responseGetCredentialsForIdentityCommand = await client.send(
        commandGetCredentialsForIdentityCommand,
      );
      const credentials = responseGetCredentialsForIdentityCommand.Credentials;

      if (
        !credentials ||
        !credentials.AccessKeyId ||
        !credentials.SecretKey ||
        !credentials.SessionToken
      ) {
        throw new Error('Credentials not found');
      }
      const s3Client = new S3Client({
        region,
        credentials: {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretKey,
          sessionToken: credentials.SessionToken,
        },
      });
      resolve(s3Client);
    } catch (e) {
      reject(e);
    }
  });
};
