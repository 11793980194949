import Close from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import type { OnResultFunction } from 'react-qr-reader';
import { QrReader } from 'react-qr-reader';

import {
  useGetSessionsQuery,
  useLazyGetScanBarcodeQuery,
  useLazyGetSessionsQuery,
  usePostUserCheckoutMutation,
} from '../services/checkin';
import type { RootState } from '../store';
import { useAppDispatch, useAppSelector } from '../store';
import { addScanUser } from '../store/checkinSlice';

export const Scanner = () => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [checkinDialog, setCheckinDialog] = useState({
    open: false,
    checkinStatus: '',
  });

  const [userCheckInQuery] = useLazyGetScanBarcodeQuery();
  const dispatch = useAppDispatch();
  const [sessionsQuery] = useLazyGetSessionsQuery();

  const [userCheckoutMutation] = usePostUserCheckoutMutation();
  const { data } = useGetSessionsQuery();

  const checkedInBarcodes = data?.map((d) => d.barcode);

  const scannedUsers = useAppSelector(
    (state: RootState) => state.checkin.scannedUsers,
  );

  const resetCheckinDialog = useCallback(() => {
    setCheckinDialog({
      open: false,
      checkinStatus: '',
    });
  }, []);

  const onAddRemoveUser = (barcode: string) => {
    if (barcode) {
      // If user is already checkedIn, checkout
      if (checkedInBarcodes && checkedInBarcodes.includes(barcode)) {
        setCheckinDialog({
          open: true,
          checkinStatus: 'CHECKING_OUT',
        });
        userCheckoutMutation({
          barcode,
        })
          .then(() => {
            setCheckinDialog((prev) => ({
              ...prev,
              checkinStatus: 'CHECKED_OUT',
            }));
            sessionsQuery();
          })
          .finally(() => {
            setTimeout(() => {
              resetCheckinDialog();
            }, 3000);
          });
      }
      // Else checkin if not already checkedin
      else if (!scannedUsers.find((s) => s.barcode === barcode)) {
        setCheckinDialog({
          open: true,
          checkinStatus: 'CHECKING_IN',
        });
        userCheckInQuery({
          barcode,
        })
          .then((payload) => {
            setCheckinDialog((prev) => ({
              ...prev,
              checkinStatus: 'CHECKED_IN',
            }));
            if (payload.data) {
              dispatch(addScanUser(payload.data));
            }
          })
          .finally(() => {
            setTimeout(() => {
              resetCheckinDialog();
            }, 3000);
          });
      }
    }
  };

  const onScan: OnResultFunction = (result) => {
    if (result && result.getText()) {
      try {
        const barcode = JSON.parse(result.getText());
        console.log('res', barcode);
        if (barcode && !checkinDialog.open) onAddRemoveUser(barcode);
      } catch (e) {}
    }
  };

  return (
    <Box className="absolute bottom-10 right-10 flex gap-2 flex-col">
      <Fab size="medium" onClick={() => setOpenAddUser(true)}>
        <QrCodeScannerIcon />
      </Fab>
      <Dialog
        open={openAddUser}
        // onClose={() => setOpenAddUser(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title" className="flex items-center">
          <div className="flex-1">Please scan the barcode</div>
          <IconButton onClick={() => setOpenAddUser(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <QrReader
            key={Math.random().toString()}
            onResult={onScan}
            constraints={{
              facingMode: { ideal: 'environment' },
              aspectRatio: 1,
            }}
            videoContainerStyle={{ height: '50vh', paddingTop: '0px' }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={checkinDialog.open} onClose={resetCheckinDialog}>
        <Box className="w-80 h-64 flex flex-col justify-center items-center gap-3 p-4">
          {checkinDialog.checkinStatus === 'CHECKING_IN' ||
          checkinDialog.checkinStatus === 'CHECKING_OUT' ? (
            <CircularProgress size={48} />
          ) : (
            <Box className="w-20 h-20 bg-kc-primary rounded-full flex items-center justify-center">
              <DoneAllIcon className="text-white text-5xl" />
            </Box>
          )}
          <Typography className="font-bold text-kc-grey text-2xl">
            {`${checkinDialog.checkinStatus === 'CHECKING_IN' ? 'Checking In' : ''}
            ${checkinDialog.checkinStatus === 'CHECKING_OUT' ? 'Checking Out' : ''}
            ${checkinDialog.checkinStatus === 'CHECKED_IN' ? 'Checked In' : ''}
            ${checkinDialog.checkinStatus === 'CHECKED_OUT' ? 'Checked Out' : ''}`}
          </Typography>
        </Box>
      </Dialog>
    </Box>
  );
};
