import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Input } from '../components/Input';
import { Phone, getCodeAndNumber } from '../components/Phone';
import { ProfilePicSelector } from '../components/ProfilePicSelector';
import { getProfileImage } from '../utils/getProfileImage';
import { uploadProfileImage } from '../utils/uploadProfileImage';

type FormValuesType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: { code: string; number: string };
  picture?: string;
};
const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.object().shape({
    code: yup.string().required('Phone number is required'),
    number: yup
      .number()
      .typeError('Invalid phone number')
      .required('Phone number is required'),
  }),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

export const UpdateProfileDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ open, onClose, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik<FormValuesType>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: { code: '+44', number: '' },
      picture: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.validateForm(values);
      setIsLoading(true);
      try {
        await updateUserAttributes({
          userAttributes: {
            name: values.firstName,
            family_name: values.lastName,
            phone_number: values.phone.code + values.phone.number,
          },
        });
        if (values.picture) {
          const json = await fetch(values.picture);
          const blob = await json.blob();
          await uploadProfileImage(blob);
        }
        onSuccess();
        setOpenSuccess(true);
        onClose();
      } catch (e) {
        setErrorMessage(e.message);
        setOpenFailure(true);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    setIsLoading(true);
    fetchUserAttributes()
      .then(async (val) => {
        formik.setFieldValue('firstName', val.name);
        formik.setFieldValue('lastName', val.family_name);
        formik.setFieldValue('email', val.email);
        formik.setFieldValue('phone', {
          code: getCodeAndNumber(val.phone_number || '').code,
          number: getCodeAndNumber(val.phone_number || '').number,
        });
        const imageUrl = await getProfileImage();
        formik.setFieldValue('picture', imageUrl);
      })
      .catch((e) => {
        setErrorMessage(e.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent className="w-[500px] p-6">
          <form
            onSubmit={formik.handleSubmit}
            className="flex gap-1 flex-col items-center justify-center"
          >
            <Typography className="text-3xl font-bold text-kc-grey mb-4">
              Profile Details
            </Typography>
            <ProfilePicSelector
              name="picture"
              value={formik.values.picture}
              onChange={async (val) => formik.setFieldValue('picture', val)}
            />
            <Stack direction={'row'} gap={2} width={'100%'}>
              <Input
                name="firstName"
                label="Firstname"
                placeholder="Arthur"
                fullWidth
                required
                disabled
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                errorMessage={
                  formik.touched.firstName ? formik.errors.firstName : ''
                }
              />
              <Input
                name="lastName"
                label="Lastname"
                placeholder="Duncan"
                fullWidth
                required
                disabled
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                errorMessage={
                  formik.touched.lastName ? formik.errors.lastName : ''
                }
              />
            </Stack>
            <Phone
              name="phone"
              label="Mobile Number"
              placeholder="123456789"
              fullWidth
              required
              value={formik.values.phone}
              onChange={(value) => {
                formik.setFieldValue('phone', value);
                formik.setTouched({ phone: { code: true, number: true } });
              }}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              errorMessage={
                formik.touched.phone?.number ? formik.errors.phone?.number : ''
              }
            />
            <Input
              disabled
              name="email"
              label="Email"
              placeholder="xyz@gmail.com"
              fullWidth
              required
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              errorMessage={formik.touched.email ? formik.errors.email : ''}
            />
            <Box className="flex flex-col gap-1 w-full">
              <Button
                type="submit"
                disableRipple
                disabled={isLoading}
                className="w-full normal-case rounded-[32px] bg-kc-primary text-white font-semibold hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 h-12"
              >
                {isLoading && (
                  <CircularProgress size={16} className="text-kc-grey-4 mr-6" />
                )}
                Submit
              </Button>
              <Button
                onClick={() => onClose()}
                disableRipple
                className="w-full normal-case rounded-[32px] text-kc-grey font-semibold hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 h-10"
              >
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openSuccess} onClose={() => setOpenSuccess(false)}>
        <Box className="w-80 h-64 flex flex-col justify-center items-center gap-3">
          <Box className="w-20 h-20 bg-kc-primary rounded-full flex items-center justify-center">
            <DoneAllIcon className="text-white text-5xl" />
          </Box>
          <Typography className="font-bold text-kc-grey text-2xl">
            Profile updated!
          </Typography>
        </Box>
      </Dialog>
      <Dialog open={openFailure} onClose={() => setOpenFailure(false)}>
        <Box className="w-80 h-64 flex flex-col justify-center items-center gap-3">
          <Box className="w-20 h-20 bg-kc-red rounded-full flex items-center justify-center">
            <CancelIcon className="text-white text-5xl" />
          </Box>
          <Typography className="font-bold text-kc-grey text-2xl">
            Profile update failed!
          </Typography>
          <Typography className="font-medium text-kc-grey text-lg mx-2">
            {errorMessage}
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};
