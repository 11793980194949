import type {
  AdminDisableUserCommandInput,
  AdminEnableUserCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  AdminDisableUserCommand,
  AdminEnableUserCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { useCallback, useState } from 'react';

import { getCognitoIdentityProviderClient } from '../../../utils/getCognitoIdentityProviderClient';
import { awsConfig } from '../../auth/config';

export const useActivateUser = (): [
  (email: string, activate: boolean) => Promise<unknown>,
  {
    isLoading: boolean;
    error: unknown;
  },
] => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const activate = useCallback(async (email: string, activate: boolean) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const identityProviderClient = await getCognitoIdentityProviderClient();

        if (!activate) {
          // activate user
          const adminDisableUserCommandInput: AdminDisableUserCommandInput = {
            UserPoolId: awsConfig.userPoolId,
            Username: email,
          };
          const adminDisableUserCommand = new AdminDisableUserCommand(
            adminDisableUserCommandInput,
          );
          await identityProviderClient.send(adminDisableUserCommand);
        } else {
          // activate user
          const adminEnableUserCommandInput: AdminEnableUserCommandInput = {
            UserPoolId: awsConfig.userPoolId,
            Username: email,
          };
          const adminEnableUserCommand = new AdminEnableUserCommand(
            adminEnableUserCommandInput,
          );
          await identityProviderClient.send(adminEnableUserCommand);
        }

        resolve(null);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
        reject(e);
      }
    });
  }, []);

  return [activate, { isLoading, error }];
};
