import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import FieldInput from '../../../components/FieldInput';
import {
  useAddPromoCodeMutation,
  useUpdatePromoCodeMutation,
} from '../../../services/promo_code';
import formatDate from '../../../utils/formatDate';

interface InitialValues {
  promoCode: string;
  promoDescription: string;
  promoMinutes: string | number;
  expiryDate: any;
}

interface Properties {
  open: boolean;
  isEditMode: boolean;
  rowData?: any;
  refetch: () => void;
  handleClose: () => void;
}

const validationSchema = Yup.object({
  promoCode: Yup.string().trim().required('Promo Code is required'),
  expiryDate: Yup.string().required('Expiray Date is required'),
  promoMinutes: Yup.number()
    .required('Minutes are required')
    .positive('Minutes must be a positive number')
    .integer('Minutes must be an integer'),
});

const AddPromoCode = ({
  open,
  handleClose,
  isEditMode,
  rowData,
  refetch,
}: Properties) => {
  const [updatePromoCode, { isLoading }] = useUpdatePromoCodeMutation();
  const [addPromoCode] = useAddPromoCodeMutation();
  const [initialValues, setInitialValues] = useState<InitialValues>({
    promoCode: '',
    promoDescription: '',
    promoMinutes: '',
    expiryDate: new Date(),
  });

  const [successOpen, setSuccessOpen] = useState(false);

  const onSubmit = (values: InitialValues) => {
    values.expiryDate = values.expiryDate + 'T23:59:59';
    if (isEditMode) {
      updatePromoCode({ promoCodeId: values.id, payload: values }).then(
        (data: any) => {
          if (!data.error) {
            refetch();
            setSuccessOpen(true);
            handleClose();
          }
        },
      );
    } else {
      addPromoCode(values).then((data: any) => {
        if (!data.error) {
          refetch();
          setSuccessOpen(true);
          handleClose();
        }
      });
    }
  };

  useEffect(() => {
    if (isEditMode && rowData) {
      setInitialValues({
        ...rowData,
        expiryDate: formatDate(rowData.expiryDate, 'YYYY-MM-DD'),
      });
    }
  }, [rowData]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="text-3xl text-kc-grey font-bold">
          {!isEditMode ? 'Add New Promo Code' : 'Update Promo Code'}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-2 mt-2 p-1">
                  <div className="col-span-6 p-1">
                    <FieldInput
                      name="promoCode"
                      label="Promo Code"
                      placeholder="Promo Code"
                      type="text"
                      required={true}
                      isEditMode={isEditMode}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-6 p-1">
                    <FieldInput
                      name="promoMinutes"
                      label="Minutes"
                      placeholder=" Minutes"
                      type="number"
                      required={true}
                      disabled={true}
                      isEditMode={isEditMode}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-2 p-1">
                  <div className="col-span-6 p-1">
                    <Typography className="text-kc-grey font-bold mb-1">
                      Validity <span className="text-red-500"> *</span>
                    </Typography>
                    <DatePicker
                      value={dayjs(initialValues?.expiryDate)}
                      format="DD/MM/YYYY"
                      disablePast
                      onChange={(date: any) => {
                        setFieldValue(
                          'expiryDate',
                          formatDate(new Date(date), 'YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-2 mt-2 p-1">
                  <div className="col-span-12 p-1">
                    <FieldInput
                      name="promoDescription"
                      label="Description"
                      placeholder=" Description"
                      type="text"
                      isEditMode={isEditMode}
                      disabled={true}
                    />
                  </div>
                </div>

                <Stack direction={'row'}>
                  <Box className="flex-1"></Box>
                  <Stack direction="row" gap={1}>
                    <Button
                      disableRipple
                      disabled={isLoading}
                      onClick={handleClose}
                      className="normal-case rounded-[32px] bg-white text-kc-grey border-solid border-kc-primary border-2 font-semibold hover:bg-kc-white hover:text-kc-grey hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 w-32 h-12"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disableRipple
                      disabled={isLoading}
                      className="normal-case rounded-[32px] bg-kc-primary text-white font-semibold hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4 w-32 h-12"
                    >
                      {isLoading && (
                        <CircularProgress
                          size={16}
                          className="text-kc-grey-4 mr-6"
                        />
                      )}
                      {!isEditMode ? 'Submit' : 'Update'}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog
        open={successOpen}
        onClose={() => {
          setSuccessOpen(false);
          handleClose();
        }}
      >
        <Box className="w-80 h-64 flex flex-col justify-center items-center gap-3">
          <Box className="w-20 h-20 bg-kc-primary rounded-full flex items-center justify-center">
            <DoneAllIcon className="text-white text-5xl" />
          </Box>
          <Typography className="font-bold text-kc-grey text-2xl">
            {isEditMode ? 'Promocode Updated!' : 'Promocode Added'}
          </Typography>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddPromoCode;
