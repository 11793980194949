import { PutObjectCommand } from '@aws-sdk/client-s3';
import { fetchUserAttributes } from 'aws-amplify/auth';

import { awsConfig } from '../features/auth/config';

import { getS3Client } from './getS3Client';

export const uploadProfileImage = async (file: Blob, profileId?: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!file) {
        throw new Error('Please select a profile photo to upload.');
      }
      // Validate file size (below 5 MB)
      if (file.size > 5 * 1024 * 1024) {
        // 5 MB
        throw new Error('Profile photo size must be less than 5 MB.');
      }
      // Validate file type (JPEG/JPG)
      const validFileTypes = ['image/jpeg', 'image/jpg'];
      if (!validFileTypes.includes(file.type)) {
        throw new Error('Only JPEG/JPG files are allowed as profile photo.');
      }

      const attributes = await fetchUserAttributes();
      const sub = attributes.sub;

      const s3Client = await getS3Client();
      const params = {
        Bucket: profileId ? awsConfig.userBucket : awsConfig.bucket,
        Key: profileId
          ? `${sub}/${profileId}/profile.jpg`
          : `${sub}/profile.jpg`,
        Body: file,
        ContentType: file.type,
      };

      const command = new PutObjectCommand(params);
      const response = await s3Client.send(command);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
};
