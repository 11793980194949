import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useMemo, useState } from 'react';

import { getProfileImage } from '../../utils/getProfileImage';

dayjs.extend(duration);

export const ChildProfileCard: React.FC<{
  id: number;
  name: string;
  age: number;
  subId?: string;
  accountId?: number;
  parentName?: string;
  parentLastName?: string;
  contactNumber?: string;
  alternateNumber?: string;
  firstAidInfo?: string;
  sessionStatus?: string;
  checkinTime?: string;
  estimatedEndTime?: string;
  availableMinutes?: number;
  startingSession?: boolean;
  movingToWaiting?: boolean;
  picture?: string;
  onStartSession?: (id: number) => void;
  onEndSession?: (id: number) => void;
  onMoveToWaiting?: (id: number) => void;
}> = ({
  id,
  name,
  age,
  subId,
  parentName,
  parentLastName,
  contactNumber,
  alternateNumber,
  firstAidInfo,
  sessionStatus,
  checkinTime: tempCheckinTime,
  estimatedEndTime: tempEstimatedEndTime,
  availableMinutes,
  startingSession,
  movingToWaiting,
  onStartSession,
  onEndSession,
  onMoveToWaiting,
}) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    getProfileImage(subId, id)
      .then(setImage)
      .catch(() => {});
  }, []);

  const checkinTime = !tempCheckinTime?.endsWith('Z')
    ? tempCheckinTime + 'Z'
    : tempCheckinTime;
  const estimatedEndTime = !tempEstimatedEndTime?.endsWith('Z')
    ? tempEstimatedEndTime + 'Z'
    : tempEstimatedEndTime;

  const [showProfile, setShowProfile] = useState(false);
  const [timeRemainingInSec, setTimeRemainingInSec] = useState(0);
  const sessionStarted =
    (sessionStatus === 'ACTIVE' || sessionStatus === 'IDLE') && checkinTime;

  const timeAllotedInSec = (() => {
    if (checkinTime && estimatedEndTime) {
      const timestampX = dayjs(checkinTime);
      const timestampY = dayjs(estimatedEndTime);
      const remaining = timestampY.diff(timestampX) / 1000;
      return remaining < 0 ? 0 : remaining;
    }
    if (!sessionStarted && availableMinutes) {
      return availableMinutes * 60;
    }
    return 0;
  })();

  useEffect(() => {
    if (!sessionStarted && availableMinutes) {
      setTimeRemainingInSec(availableMinutes * 60);
    }
  }, [sessionStarted, availableMinutes]);

  const timingStatus = useMemo(() => {
    let status = 'default';
    if (sessionStarted && sessionStatus !== 'IDLE') {
      if (timeRemainingInSec < 15 * 60) status = 'warning';
      if (timeRemainingInSec < 5 * 60) {
        if (timeRemainingInSec > 0) {
          status =
            Math.round(timeRemainingInSec) % 2 === 0 ? 'default' : 'error';
        } else {
          status = 'error';
        }
      }
    }
    return status;
  }, [timeRemainingInSec]);

  useEffect(() => {
    if (sessionStarted) {
      const cal = () => {
        const timestampX = dayjs();
        const timestampY = dayjs(estimatedEndTime);
        const remaining = timestampY.diff(timestampX) / 1000;
        setTimeRemainingInSec(remaining < 0 ? 0 : remaining);
      };
      cal();
      if (estimatedEndTime && sessionStatus !== 'IDLE') {
        const interval = setInterval(cal, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [estimatedEndTime, sessionStatus, sessionStarted]);

  const toggleProfile = () => setShowProfile((prev) => !prev);

  if (showProfile) {
    return (
      <Card className="w-64 rounded-lg shadow-kc-input bg-kc-primary p-4 box-border h-[336px]">
        <CardContent className="relative p-2 flex flex-col gap-1 last:pb-2 bg-white rounded items-center h-[calc(100%-16px)]">
          <IconButton
            className="absolute right-0 -top-[2px]"
            onClick={toggleProfile}
          >
            <CloseIcon />
          </IconButton>
          <Typography className="text-[23px] leading-[32px] text-kc-grey-10 font-semibold mt-2 truncate max-w-[90%]">
            {name}
          </Typography>
          <Box className="flex flex-col justify-center items-center gap-1 w-full">
            <Typography className="text-[13px] leading-[18px] text-kc-grey-9 font-medium">
              Account Holder Name
            </Typography>
            <Typography className="text-base leading-[22px] text-kc-grey-10 font-semibold truncate max-w-[90%]">
              {`${parentName} ${parentLastName}`}
            </Typography>
          </Box>
          <Box className="flex flex-col justify-center items-center gap-1 w-full">
            <Typography className="text-[13px] leading-[18px] text-kc-grey-9 font-medium">
              Contact Number
            </Typography>
            <Typography className="text-base leading-[22px] text-kc-grey-10 font-semibold truncate max-w-[90%]">
              {contactNumber}
            </Typography>
            <Typography className="text-base leading-[22px] text-kc-grey-10 font-semibold truncate max-w-[90%]">
              {alternateNumber}
            </Typography>
          </Box>
          <Box className="flex flex-col justify-center items-center gap-1 w-full">
            <Typography className="text-[13px] leading-[18px] text-kc-grey-9 font-medium">
              First Aid Information
            </Typography>
            <Typography className="text-base line-clamp-2 text-ellipsis leading-[22px] text-kc-grey-10 font-semibold text-center px-2 h-11">
              {firstAidInfo}
            </Typography>
          </Box>
          <Box className="bg-kc-grey-11 h-[1px] w-[calc(100%-32px)] border-[1px] border-kc-grey-11 border-solid" />
          <Button
            className="normal-case w-full bg-kc-primary h-11 text-white rounded-[32px] text-base font-medium hover:opacity-80 hover:bg-kc-primary transition-opacity duration-200 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
            onClick={() => onMoveToWaiting && onMoveToWaiting(id)}
            disabled={
              timeRemainingInSec !== 0 ||
              sessionStatus === 'IDLE' ||
              movingToWaiting
            }
          >
            {movingToWaiting && (
              <CircularProgress className="text-kc-grey-4 mr-2" size={24} />
            )}
            Move to Waiting Area
          </Button>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card
      className={clsx(
        'w-64 rounded-lg shadow-kc-input box-border h-[336px] transition-colors duration-200',
        {
          'bg-kc-primary': timingStatus === 'warning',
          'bg-kc-red': timingStatus === 'error',
          'bg-white': timingStatus === 'default',
        },
      )}
    >
      <CardMedia className="h-32" image={image} title={name} />
      <CardContent className="p-2 flex flex-col gap-1 last:pb-2">
        <Box
          className={clsx(
            'flex font-medium text-base gap-2 transition-colors duration-200',
            {
              'text-white':
                timingStatus === 'warning' || timingStatus === 'error',
              'text-kc-grey': timingStatus === 'default',
            },
          )}
        >
          <Typography className="flex-1 truncate">{name}</Typography>
          <Typography>Age: {age}</Typography>
        </Box>
        <Box className="flex flex-col gap-2 py-3 justify-center items-center">
          <Typography
            className={clsx(
              'font-medium text-2xl transition-colors duration-200',
              {
                'text-white':
                  timingStatus === 'warning' || timingStatus === 'error',
                'text-kc-normal-blue': timingStatus === 'default',
              },
            )}
          >
            {`${dayjs.duration(timeRemainingInSec, 'seconds').hours() > 0 ? String(dayjs.duration(timeRemainingInSec, 'seconds').hours()).padStart(2, '0') + ':' : ''}${String(dayjs.duration(timeRemainingInSec, 'seconds').minutes()).padStart(2, '0')}:${String(dayjs.duration(timeRemainingInSec, 'seconds').seconds()).padStart(2, '0')}`}
          </Typography>
          <Box className="relative w-24 h-1">
            <Box className="absolute rounded-[60px] bg-kc-light-blue w-full h-full"></Box>
            <Box
              className="absolute rounded-[60px] bg-kc-normal-blue h-full"
              width={`${(timeRemainingInSec / timeAllotedInSec) * 100}%`}
            ></Box>
          </Box>
        </Box>
        <Box className="gap-1 flex flex-col">
          <Button
            className={clsx(
              'normal-case w-full h-11 rounded-[32px] text-base font-medium hover:opacity-80 transition-all duration-200',
              {
                'hover:bg-white bg-white text-black':
                  timingStatus === 'warning' || timingStatus === 'error',
                'hover:bg-kc-primary bg-kc-primary text-white':
                  timingStatus === 'default',
              },
            )}
            onClick={toggleProfile}
          >
            View profile
          </Button>
          {!sessionStarted && (
            <Button
              className={clsx(
                'normal-case w-full h-11 rounded-[32px] text-base font-medium hover:opacity-90 transition-all duration-200 disabled:bg-kc-grey-12 disabled:text-kc-grey-4',
                {
                  'hover:bg-white bg-white text-black':
                    timingStatus === 'warning' || timingStatus === 'error',
                  'hover:bg-kc-normal-blue bg-kc-normal-blue text-white':
                    timingStatus === 'default',
                },
              )}
              disabled={startingSession}
              onClick={() => onStartSession && onStartSession(id)}
            >
              {startingSession && (
                <CircularProgress className="text-kc-grey-4 mr-2" size={24} />
              )}
              Start Session
            </Button>
          )}
          {sessionStarted && (
            <Button
              className={clsx(
                'normal-case w-full h-11 rounded-[32px] text-base font-medium hover:opacity-90 transition-all duration-200',
                {
                  'hover:bg-white bg-white text-black':
                    timingStatus === 'warning' || timingStatus === 'error',
                  'hover:bg-kc-red bg-kc-red text-white':
                    timingStatus === 'default',
                },
              )}
              onClick={() => onEndSession && onEndSession(id)}
            >
              End Session
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
