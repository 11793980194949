import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ky from 'ky';

export const packageApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_SERVICE_URL}/uaps/api/v1`,
    fetchFn: async (...args) => ky(...args),
  }),

  endpoints: (builder) => ({
    getPackageApi: builder.query({
      query: () => `/packages`,
    }),
    getPackageById: builder.query({
      query: (packageId) => `/packages/${packageId}`,
    }),
    updatePackage: builder.mutation({
      query: ({ packageId, payload }) => ({
        url: `/packages/${packageId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    addPackage: builder.mutation({
      query: (payload) => ({
        url: `/packages`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),

  reducerPath: 'packageApi',
});

export const {
  useGetPackageApiQuery,
  useGetPackageByIdQuery,
  useAddPackageMutation,
  useUpdatePackageMutation,
} = packageApi;
