import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity';
import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
import { fetchAuthSession } from 'aws-amplify/auth';

import { awsConfig } from '../features/auth/config';

export const getCognitoIdentityProviderClient =
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  (): Promise<CognitoIdentityProviderClient> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetchAuthSession();
        const idToken = res.tokens?.idToken?.toString();

        if (!idToken) throw new Error('Token not found');
        const identityClient = new CognitoIdentityClient({
          region: awsConfig.region,
        });
        const getIdInput = {
          IdentityPoolId: awsConfig.identityPoolId,
          Logins: {
            [awsConfig.userPoolLogins]: idToken,
          },
        };
        const getIdCommand = new GetIdCommand(getIdInput);
        const getIdResult = await identityClient.send(getIdCommand);

        // Get credentials
        if (!getIdResult.IdentityId) {
          throw new Error('Failed to get Id');
        }

        const getCredsForIdentityInput = {
          IdentityId: getIdResult.IdentityId,
          Logins: {
            [awsConfig.userPoolLogins]: idToken,
          },
        };
        const getCredsForIdentityCommand = new GetCredentialsForIdentityCommand(
          getCredsForIdentityInput,
        );
        const getCredsForIdentityResult = await identityClient.send(
          getCredsForIdentityCommand,
        );

        if (
          !getCredsForIdentityResult.Credentials ||
          !getCredsForIdentityResult.Credentials.AccessKeyId ||
          !getCredsForIdentityResult.Credentials.SecretKey ||
          !getCredsForIdentityResult.Credentials.SessionToken
        ) {
          throw new Error('Credentials not found');
        }

        const identityProviderClient = new CognitoIdentityProviderClient({
          region: awsConfig.region,
          credentials: {
            accessKeyId: getCredsForIdentityResult.Credentials.AccessKeyId,
            secretAccessKey: getCredsForIdentityResult.Credentials.SecretKey,
            sessionToken: getCredsForIdentityResult.Credentials.SessionToken,
          },
        });
        resolve(identityProviderClient);
      } catch (e) {
        reject(e);
      }
    });
  };
