import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Box, Button, InputBase, Menu, MenuItem } from '@mui/material';
import { fetchUserAttributes } from 'aws-amplify/auth';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UpdateProfileDialog } from '../features/UpdateProfileDialog';
import type { RootState } from '../store';
import { useAppDispatch, useAppSelector } from '../store';
import { setGlobalSearch } from '../store/searchSlice';
import { getProfileImage } from '../utils/getProfileImage';

import { SidebarMobile } from './SidebarMobile';

export const TopBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [userName, setUserName] = useState<string>('User');
  const [userPic, setUserPic] = useState<string>();
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const globalSearchText = useAppSelector(
    (state: RootState) => state.search.searchText,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchUserPic = async () => {
    const imageUrl = await getProfileImage();
    setUserPic(imageUrl);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchUserAttributes();
        setUserName(res.name || 'User');
        fetchUserPic();
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    dispatch(setGlobalSearch(''));
  }, [location.pathname]);

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setGlobalSearch(e.target.value));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="flex gap-4 w-full items-center">
      <Box className="mobile:block tablet:hidden desktop:hidden">
        <SidebarMobile />
      </Box>
      <Box className="flex gap-2 px-3 py-2 shadow-[0px 1px 4px 0px #00000029] flex-1 shadow-kc-input rounded-lg items-center justify-center bg-white">
        <InputBase
          className="flex-1"
          placeholder="Search"
          onChange={onSearch}
          value={globalSearchText}
        />
        <SearchIcon className="text-kc-grey-7" />
      </Box>
      <Box>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          className="text-black normal-case px-2 flex gap-2"
        >
          <Avatar alt="User" className="w-7 h-7" src={userPic} />
          {userName}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PopoverClasses={{
            paper: 'w-36 mt-1',
          }}
          MenuListProps={{ className: 'p-1' }}
        >
          <MenuItem
            className="text-sm font-normal w-"
            disableRipple
            onClick={() => {
              setProfileDialogOpen(true);
              handleClose();
            }}
          >
            View Profile
          </MenuItem>
        </Menu>
        <UpdateProfileDialog
          open={profileDialogOpen}
          onClose={() => setProfileDialogOpen(false)}
          onSuccess={fetchUserPic}
        />
      </Box>
    </Box>
  );
};
