import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Fade,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import clsx from 'clsx';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import { useGetBookingForDateQuery } from '../../services/booking';
import type { RootState } from '../../store';
import { useAppSelector } from '../../store';

type BookingType = Array<{
  slotId: number;
  time: string;
  reservations: Array<{ id: number; name: string }>;
}>;
const resData: BookingType = [
  {
    slotId: 1,
    time: '9:00 AM',
    reservations: [],
  },
  {
    slotId: 2,
    time: '10:00 AM',
    reservations: [],
  },
  {
    slotId: 3,
    time: '11:00 AM',
    reservations: [],
  },
  {
    slotId: 4,
    time: '12:00 PM',
    reservations: [],
  },
  {
    slotId: 5,
    time: '1:00 PM',
    reservations: [],
  },
  {
    slotId: 6,
    time: '2:00 PM',
    reservations: [],
  },
  {
    slotId: 7,
    time: '3:00 PM',
    reservations: [],
  },
  {
    slotId: 8,
    time: '4:00 PM',
    reservations: [],
  },
  {
    slotId: 9,
    time: '5:00 PM',
    reservations: [],
  },
  {
    slotId: 10,
    time: '6:00 PM',
    reservations: [],
  },
];

const timeToSlotIndex: Record<string, number> = {
  '09:00:00': 0,
  '10:00:00': 1,
  '11:00:00': 2,
  '12:00:00': 3,
  '13:00:00': 4,
  '14:00:00': 5,
  '15:00:00': 6,
  '16:00:00': 7,
  '17:00:00': 8,
  '18:00:00': 9,
};

export const Reservations: React.FC<{}> = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [openCalendar, setOpenCalendar] = useState(false);
  const { data, error, isLoading, isFetching } = useGetBookingForDateQuery({
    date: selectedDate.format('YYYY-MM-DD'),
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reservations, setReservations] = useImmer(resData);
  const globalSearchText = useAppSelector(
    (state: RootState) => state.search.searchText,
  );
  useEffect(() => {
    if (error || isFetching || isLoading) {
      setReservations(resData);
    } else if (data && data.length > 0) {
      setReservations((res) => {
        data.forEach((d) => {
          res?.[timeToSlotIndex[d.booking.sessionTime]]?.reservations.push({
            id: d.booking.profileId,
            name: d.profileDetails.profileFirstName.toString(),
          });
        });
      });
    }
  }, [data, error, isLoading]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenCalendar((prev) => !prev);
  };

  const onDateSelect = (value: Dayjs) => {
    setOpenCalendar((prev) => !prev);
    setSelectedDate(value);
  };

  return (
    <Box className="shadow-kc-input bg-white rounded-lg overflow-auto p-4">
      <Stack className="items-center flex-row mb-2">
        <Typography className="font-semibold text-kc-grey ">
          {`Date: ${selectedDate.format('MMMM DD, YYYY')}`}
        </Typography>
        <IconButton aria-label="calendar" size="small" onClick={handleClick}>
          <KeyboardArrowDownIcon />
        </IconButton>
        <Popper
          open={openCalendar}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <DateCalendar
                  value={selectedDate}
                  onChange={onDateSelect}
                  slotProps={{
                    day: {
                      sx: {
                        '&.Mui-selected': {
                          backgroundColor: 'rgb(243 146 0)',
                          '&:hover': {
                            backgroundColor: 'rgb(243 146 0)',
                            opacity: '0.8',
                          },
                        },
                      },
                    },
                  }}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </Stack>
      <Box className="flex gap-4">
        <Box className="flex flex-col gap-1">
          {reservations.map((res) => (
            <>
              <Box
                className="h-[72px] flex flex-col gap-2 items-center justify-center box-border"
                key={res.slotId}
              >
                <Typography className="bg-kc-primary text-white text-[11px] px-2 py-[2px] rounded-[60px]">{`• Reservations = ${res.reservations.length}`}</Typography>
                <Typography className="text-kc-grey text-base font-medium">
                  {res.time}
                </Typography>
              </Box>
              <Box className="h-[1px] bg-kc-grey w-full opacity-50" />
            </>
          ))}
        </Box>
        <Box className="flex flex-1 gap-1 flex-col w-96">
          {reservations.map((res, i) => (
            <>
              <Box className="flex gap-2 overflow-auto" key={res.slotId}>
                {res.reservations.length === 0 && (
                  <Box className="h-[72px] flex items-center justify-center px-5 w-full">
                    <Typography className="text-lg text-kc-grey">
                      No reservations
                    </Typography>
                  </Box>
                )}
                {res.reservations
                  .filter(
                    (r) =>
                      !globalSearchText ||
                      r.name.toLowerCase().includes(globalSearchText),
                  )
                  .map((r, j) => (
                    <Box
                      className={clsx(
                        'h-[72px] min-w-52 w-52 rounded-lg flex gap-2 p-2 box-border',
                        {
                          'bg-kc-grey-13': (i + j) % 2 === 0,
                          'bg-kc-light-blue-3': (i + j) % 2 !== 0,
                        },
                      )}
                      key={r.id}
                    >
                      <Box
                        className={clsx('h-full w-[3px] rounded-[60px]', {
                          'bg-kc-primary': (i + j) % 2 === 0,
                          'bg-kc-normal-blue': (i + j) % 2 !== 0,
                        })}
                      />
                      <Typography className="text-kc-grey font-medium text-base leading-[22px] line-clamp-2">
                        {r.name}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              <Box className="h-[1px] bg-kc-grey w-full opacity-50 flex-1" />
            </>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
