import { Visibility, VisibilityOff } from '@mui/icons-material';
import WestIcon from '@mui/icons-material/West';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

import forgotPass from '../../../assets/new-password.svg';
import { Input } from '../../../components/Input';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase char')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase char')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least one special char',
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export const NewPassword: React.FC<{
  isLoading?: boolean;
  onNext: (password: string) => void;
  onBack: () => void;
  invalidMessage?: string;
}> = ({ onNext, onBack, isLoading, invalidMessage }) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      onNext(values.password);
    },
  });

  return (
    <Box className="border-solid border-[1px] border-kc-grey-4 flex flex-col gap-4 p-10 rounded-2xl h-fit w-[600px] items-center">
      <img src={forgotPass} className="w-12 h-12" alt="Forgot Password" />
      <Typography className="text-4xl font-bold text-kc-grey">
        Set new password
      </Typography>
      <Box className="w-full">
        <form onSubmit={formik.handleSubmit}>
          <Input
            name="password"
            label="Password"
            placeholder="••••••••"
            fullWidth
            type={showPassword1 ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            errorMessage={formik.touched.password ? formik.errors.password : ''}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  edge="end"
                >
                  {showPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Input
            name="confirmPassword"
            label="Confirm Password"
            placeholder="••••••••"
            fullWidth
            type={showPassword2 ? 'text' : 'password'}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            errorMessage={
              formik.touched.confirmPassword
                ? formik.errors.confirmPassword
                : ''
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  edge="end"
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Box className="flex-1 text-base text-kc-red font-semibold mb-4">
            {invalidMessage}
          </Box>
          <Box className="flex flex-col gap-2 mt-2">
            <Button
              fullWidth
              type="submit"
              disableRipple
              disabled={isLoading}
              className="normal-case rounded-[32px] bg-kc-primary h-12 text-white font-semibold text-[19px] hover:bg-kc-primary hover:text-white hover:opacity-80 disabled:bg-kc-grey-12 disabled:text-kc-grey-4"
            >
              {isLoading && (
                <CircularProgress size={20} className="text-kc-grey-4 mr-6" />
              )}
              Confirm
            </Button>
            <Button
              disableRipple
              onClick={onBack}
              className="normal-case text-xs text-kc-grey "
            >
              <WestIcon fontSize="small" className="mr-2" />
              Back
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
